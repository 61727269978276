exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-management-js": () => import("./../../../src/pages/admin-management.js" /* webpackChunkName: "component---src-pages-admin-management-js" */),
  "component---src-pages-area-election-js": () => import("./../../../src/pages/area-election.js" /* webpackChunkName: "component---src-pages-area-election-js" */),
  "component---src-pages-cct-js": () => import("./../../../src/pages/cct.js" /* webpackChunkName: "component---src-pages-cct-js" */),
  "component---src-pages-cctall-housedata-js": () => import("./../../../src/pages/cctall_housedata.js" /* webpackChunkName: "component---src-pages-cctall-housedata-js" */),
  "component---src-pages-comingsoon-js": () => import("./../../../src/pages/comingsoon.js" /* webpackChunkName: "component---src-pages-comingsoon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-analytics-js": () => import("./../../../src/pages/dashboard-analytics.js" /* webpackChunkName: "component---src-pages-dashboard-analytics-js" */),
  "component---src-pages-dashboard-pmt-js": () => import("./../../../src/pages/dashboard-pmt.js" /* webpackChunkName: "component---src-pages-dashboard-pmt-js" */),
  "component---src-pages-disadvantaged-disabled-country-js": () => import("./../../../src/pages/disadvantaged-disabled/country.js" /* webpackChunkName: "component---src-pages-disadvantaged-disabled-country-js" */),
  "component---src-pages-ecd-js": () => import("./../../../src/pages/ecd.js" /* webpackChunkName: "component---src-pages-ecd-js" */),
  "component---src-pages-editprofile-js": () => import("./../../../src/pages/editprofile.js" /* webpackChunkName: "component---src-pages-editprofile-js" */),
  "component---src-pages-eef-compare-js": () => import("./../../../src/pages/eef-compare.js" /* webpackChunkName: "component---src-pages-eef-compare-js" */),
  "component---src-pages-eef-dashboard-js": () => import("./../../../src/pages/eef-dashboard.js" /* webpackChunkName: "component---src-pages-eef-dashboard-js" */),
  "component---src-pages-forgetpass-js": () => import("./../../../src/pages/forgetpass.js" /* webpackChunkName: "component---src-pages-forgetpass-js" */),
  "component---src-pages-fsql-js": () => import("./../../../src/pages/fsql.js" /* webpackChunkName: "component---src-pages-fsql-js" */),
  "component---src-pages-fullmap-abe-oosc-js": () => import("./../../../src/pages/fullmap-abe-oosc.js" /* webpackChunkName: "component---src-pages-fullmap-abe-oosc-js" */),
  "component---src-pages-fullmap-cct-js": () => import("./../../../src/pages/fullmap-cct.js" /* webpackChunkName: "component---src-pages-fullmap-cct-js" */),
  "component---src-pages-fullmap-ecd-js": () => import("./../../../src/pages/fullmap-ecd.js" /* webpackChunkName: "component---src-pages-fullmap-ecd-js" */),
  "component---src-pages-fullmap-eef-oosc-js": () => import("./../../../src/pages/fullmap-eef-oosc.js" /* webpackChunkName: "component---src-pages-fullmap-eef-oosc-js" */),
  "component---src-pages-fullmap-fsql-js": () => import("./../../../src/pages/fullmap-fsql.js" /* webpackChunkName: "component---src-pages-fullmap-fsql-js" */),
  "component---src-pages-fullmap-krt-js": () => import("./../../../src/pages/fullmap-krt.js" /* webpackChunkName: "component---src-pages-fullmap-krt-js" */),
  "component---src-pages-fullmap-targetgroup-js": () => import("./../../../src/pages/fullmap-targetgroup.js" /* webpackChunkName: "component---src-pages-fullmap-targetgroup-js" */),
  "component---src-pages-fullmap-tps-js": () => import("./../../../src/pages/fullmap-tps.js" /* webpackChunkName: "component---src-pages-fullmap-tps-js" */),
  "component---src-pages-fullmap-tsqp-js": () => import("./../../../src/pages/fullmap-tsqp.js" /* webpackChunkName: "component---src-pages-fullmap-tsqp-js" */),
  "component---src-pages-fullmap-vec-js": () => import("./../../../src/pages/fullmap-vec.js" /* webpackChunkName: "component---src-pages-fullmap-vec-js" */),
  "component---src-pages-fund-js": () => import("./../../../src/pages/fund.js" /* webpackChunkName: "component---src-pages-fund-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-krt-js": () => import("./../../../src/pages/krt.js" /* webpackChunkName: "component---src-pages-krt-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-election-js": () => import("./../../../src/pages/map-election.js" /* webpackChunkName: "component---src-pages-map-election-js" */),
  "component---src-pages-nea-detail-js": () => import("./../../../src/pages/nea_detail.js" /* webpackChunkName: "component---src-pages-nea-detail-js" */),
  "component---src-pages-nea-js": () => import("./../../../src/pages/nea.js" /* webpackChunkName: "component---src-pages-nea-js" */),
  "component---src-pages-oosc-js": () => import("./../../../src/pages/oosc.js" /* webpackChunkName: "component---src-pages-oosc-js" */),
  "component---src-pages-pisa-js": () => import("./../../../src/pages/pisa.js" /* webpackChunkName: "component---src-pages-pisa-js" */),
  "component---src-pages-pms-area-process-js": () => import("./../../../src/pages/pms-area-process.js" /* webpackChunkName: "component---src-pages-pms-area-process-js" */),
  "component---src-pages-pms-overview-js": () => import("./../../../src/pages/pms-overview.js" /* webpackChunkName: "component---src-pages-pms-overview-js" */),
  "component---src-pages-pms-target-group-js": () => import("./../../../src/pages/pms-target-group.js" /* webpackChunkName: "component---src-pages-pms-target-group-js" */),
  "component---src-pages-processlinelogin-js": () => import("./../../../src/pages/processlinelogin.js" /* webpackChunkName: "component---src-pages-processlinelogin-js" */),
  "component---src-pages-province-disadvantaged-disabled-js": () => import("./../../../src/pages/province/disadvantaged-disabled.js" /* webpackChunkName: "component---src-pages-province-disadvantaged-disabled-js" */),
  "component---src-pages-province-eef-compare-js": () => import("./../../../src/pages/province/eef-compare.js" /* webpackChunkName: "component---src-pages-province-eef-compare-js" */),
  "component---src-pages-province-fund-province-js": () => import("./../../../src/pages/province/fund_province.js" /* webpackChunkName: "component---src-pages-province-fund-province-js" */),
  "component---src-pages-province-index-js": () => import("./../../../src/pages/province/index.js" /* webpackChunkName: "component---src-pages-province-index-js" */),
  "component---src-pages-province-school-js": () => import("./../../../src/pages/province/school.js" /* webpackChunkName: "component---src-pages-province-school-js" */),
  "component---src-pages-province-school-resources-js": () => import("./../../../src/pages/province/school_resources.js" /* webpackChunkName: "component---src-pages-province-school-resources-js" */),
  "component---src-pages-province-student-statistics-js": () => import("./../../../src/pages/province/student-statistics.js" /* webpackChunkName: "component---src-pages-province-student-statistics-js" */),
  "component---src-pages-ref-all-js": () => import("./../../../src/pages/ref-all.js" /* webpackChunkName: "component---src-pages-ref-all-js" */),
  "component---src-pages-register-email-js": () => import("./../../../src/pages/register-email.js" /* webpackChunkName: "component---src-pages-register-email-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resetpass-js": () => import("./../../../src/pages/resetpass.js" /* webpackChunkName: "component---src-pages-resetpass-js" */),
  "component---src-pages-school-js": () => import("./../../../src/pages/school.js" /* webpackChunkName: "component---src-pages-school-js" */),
  "component---src-pages-student-statistics-country-js": () => import("./../../../src/pages/student-statistics/country.js" /* webpackChunkName: "component---src-pages-student-statistics-country-js" */),
  "component---src-pages-student-statistics-sungkud-js": () => import("./../../../src/pages/student-statistics/sungkud.js" /* webpackChunkName: "component---src-pages-student-statistics-sungkud-js" */),
  "component---src-pages-target-group-js": () => import("./../../../src/pages/target_group.js" /* webpackChunkName: "component---src-pages-target-group-js" */),
  "component---src-pages-target-highlight-js": () => import("./../../../src/pages/target_highlight.js" /* webpackChunkName: "component---src-pages-target-highlight-js" */),
  "component---src-pages-target-js": () => import("./../../../src/pages/target.js" /* webpackChunkName: "component---src-pages-target-js" */),
  "component---src-pages-target-resources-country-js": () => import("./../../../src/pages/target-resources/country.js" /* webpackChunkName: "component---src-pages-target-resources-country-js" */),
  "component---src-pages-target-resources-sungkud-js": () => import("./../../../src/pages/target-resources/sungkud.js" /* webpackChunkName: "component---src-pages-target-resources-sungkud-js" */),
  "component---src-pages-tps-js": () => import("./../../../src/pages/tps.js" /* webpackChunkName: "component---src-pages-tps-js" */),
  "component---src-pages-tsqp-js": () => import("./../../../src/pages/tsqp.js" /* webpackChunkName: "component---src-pages-tsqp-js" */),
  "component---src-pages-vec-js": () => import("./../../../src/pages/vec.js" /* webpackChunkName: "component---src-pages-vec-js" */)
}

